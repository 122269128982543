*{
    font-family: "Montserrat";
    text-decoration: none;
  }

.bg-cftv-form {
    width: 100%;
    min-height: 100vh;
    text-align: center;
    background-color: #2F3236;
    box-sizing: border-box;
    line-height: 1.6;
}

.bg-cftv-form .header {
  padding-top: 70px;
  margin-bottom:80px;
}

.go-back {
  height: 15px;
}

.bg-cftv-form .header Button{
  color: #FFFFFF;
  background-color: #525252;
  border-radius: 25px;
  font-size: 14pt;
  height: 100%;
  padding: 10px 60px;
  cursor: auto;
}

.camera-img {
  margin-right: 12px;
}


.form-text {
  text-align: left;
}

.form-text span {
  color: #F9F9F9;
  font-weight: 200;
  font-size: 20pt;
  margin-top: 150px;
}

.bg-cftv-form .buttons Button {
    border-radius: 20px;
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    border: 1px solid #EA5B27;
    color: #FFFFFF;
    height: 100%;
    text-align: left;
  }

.bg-cftv-options .buttons Button:hover,
.bg-cftv-options .buttons Button.active,
{
    background-image: linear-gradient(to right, #FFCF00, #EA5B27);
    color: #FFFFFF;
    border: 1px solid #2F3236;
  }

.icon-form {
  height: 35px;
  width: 8px;
  background-color: #FABA0D;
  display: inline-block;
  margin-right: 16px;
  border-radius: 4px;
}

.form p {
    color: #F9F9F9;
    font-weight: 200;
    text-align: left;
    margin-bottom: 15px;
  }

  .form p.question {
    margin-top: 20px;
    font-size: 18pt;
    margin-top: 56px;
    text-align: left;
    margin-bottom: 32px;
  }

  .border-qty {
    height: 35px;
    width: 35px;
    border-radius: 50%;
    background-image: linear-gradient(to right, #FFCF00, #EA5B27);
    position: relative
  }

  .circle-qty {
    height: 33px;
    width: 33px;
    border-radius: 50%;
    background-color: #2F3236;
    margin-left: 1px;
    margin-top: 1px;
    line-height: 34px;
    color: #F9F9F9;
    position: absolute;
  }

.form Button {
  border-radius: 20px;
  font-family: 'Montserrat', sans-serif;
  text-transform: none;
  font-size: 16px;
  border: 1px solid #EA5B27;
  color: #F9F9F9;
  height: 100%;
  }

.option-days.active {
  background-image: linear-gradient(to right, #FFCF00, #EA5B27);
  color: #FFFFFF;
  border: 1px solid #2F3236;
  cursor: pointer;
}

.form Button:hover,
.option-days:hover,
.send-form:hover {
  background-image: linear-gradient(to right, #FFCF00, #EA5B27);
  color: #FFFFFF;
  border: 1px solid #2F3236;
  cursor: pointer;
  -webkit-box-shadow: 0px 0px 22px 0px #ea5b2794;
  -moz-box-shadow: 0px 0px 22px 0px #ea5b2794;
  box-shadow: 0px 0px 22px 0px #ea5b2794;
}

.icon-button {
  font-size: 15pt;
}

.item-name {
  text-align: left;
}

.option-days {
    border-radius: 20px;
    font-family: 'Montserrat', sans-serif;
    text-transform: none;
    font-size: 16px;
    border: 1px solid #EA5B27;
    color: #F9F9F9;
    height: 100%;
    text-align: center;
    padding: 8px 32px;
    cursor: pointer;
}

.send-form {
  text-align: center;
  margin-bottom: 64px;
  margin-top: 64px;
  border-radius: 20px;
  font-family: 'Montserrat', sans-serif;
  text-transform: none;
  font-size: 16px;
  border: 1px solid #2F3236;
  color: #F9F9F9;
  height: 100%;
  text-align: center;
  padding: 8px 32px;
  cursor: pointer;
}

.vs-logo {
  height: 50px;
  margin-bottom: 32px;
}

@media (max-width: 600px) {

  .bg-cftv-form .header Button{
    color: #FFFFFF;
    background-color: #525252;
    border-radius: 25px;
    font-size: 10px;
    height: 100%;
    padding: 8px 25px;
  }

  .bg-cftv-form .header Button b {
    font-size: 12pt;
  }

}