*{
    font-family: "Montserrat";
  }

.bg-vehicle {
    width: 100vw;
    min-height: 100vh;
    text-align: center;
    line-height: 1.6;
  }

  .vehicle {
    font-weight: 200;
    color: #303236;
    font-size: 22pt;
    margin-top: 45px;
    margin-bottom: 45px;
  }

.bg-vehicle Button {
    color: #FFFFFF;
    background-color: #2F3236;
    border-radius: 25px;
    font-size: 14pt;
    margin-top: 96px;
    padding: 10px 60px;
}

.bg-vehicle Button b {
  color: #FFFFFF;
}

.bg-vehicle Button.more-info {
  margin-top: 0px;
  background-image: linear-gradient(to right, #FFCF00, #EA5B27);
}

.bg-vehicle Button.more-info:hover {
  -webkit-box-shadow: 0px 0px 22px 0px #ea5b2794;
  -moz-box-shadow: 0px 0px 22px 0px #ea5b2794;
  box-shadow: 0px 0px 22px 0px #ea5b2794;
}

.bg-vehicle img.car-img {
    margin-right: 12px;
}

@media (max-width: 600px) {

  .bg-vehicle Button {
    color: #FFFFFF;
    background-color: #2f3236;
    border-radius: 25px;
    font-size: 10px;
    height: 100%;
    margin-bottom: 32px;
  }

  .bg-vehicle Button b {
    font-size: 12pt;
  }

  .vehicle {
    margin-top: 0;
    font-size: 18pt;
  }

  .bg-vehicle Button.more-info {
    font-size: 12pt;
  }

}