*{
    font-family: "Montserrat";
    text-decoration: none;
  }

.bg-vehicle-info {
    width: 100%;
    min-height: 100vh;
    background-color: #2F3236;
    box-sizing: border-box;
    line-height: 1.6;
}

.vehicle-text span {
  color: #F9F9F9;
  font-weight: 200;
  font-size: 20pt;
  margin-top: 150px;
  text-align: center;
}

.info-text span {
  color: #F9F9F9;
  font-weight: 200;
  font-size: 16pt;
  text-align: justify;
  text-align-last: center;
  display: block;
  line-height: 1.8;
}

.send {
  text-align: center;
  margin-bottom: 64px;
  margin-top: 64px;
}

.send:hover {
  -webkit-box-shadow: 0px 0px 22px 0px #ea5b2794;
  -moz-box-shadow: 0px 0px 22px 0px #ea5b2794;
  box-shadow: 0px 0px 22px 0px #ea5b2794;
}

.send {
    border-radius: 20px;
    font-family: 'Montserrat', sans-serif;
    text-transform: none;
    font-size: 16px;
    background-image: linear-gradient(to right, #FFCF00, #EA5B27);
    color: #F9F9F9;
    height: 100%;
    text-align: center;
    padding: 8px 32px;
    cursor: pointer;
    padding: 10px;
}

.vs-logo {
  height: 50px;
  margin-bottom: 32px;
}


@media (max-width: 600px) {

  .bg-vehicle-info .header Button,
  .button-header {
    color: #FFFFFF;
    background-color: #525252;
    border-radius: 25px;
    font-size: 10px;
    height: 100%;
    padding: 8px 25px;
  }

  .MuiGrid-item.back-item {
    margin-bottom: 32px;
  }

}