*{
  font-family: "Montserrat";
  text-decoration: none;
}

.bg-cftv-thanks {
  width: 100%;
  min-height: 100vh;
  text-align: center;
  background-color: #2F3236;
  box-sizing: border-box;
  background: url(./images/alarm-bg.png) #2F3236 no-repeat 500px;
  line-height: 1.6;
}

.bg-cftv-thanks .header {
padding-top: 70px;
margin-bottom:80px;
}

.go-back {
height: 15px;
}

.bg-cftv-thanks .header Button{
color: #FFFFFF;
background-color: #525252;
border-radius: 25px;
height: 100%;
padding: 10px 32px;
cursor: auto;
}

.car-img {
margin-right: 12px;
}




.cftv-text span {
color: #F9F9F9;
font-weight: 200;
font-size: 20pt;
margin-top: 150px;
text-align: center;
}

@media (max-width: 600px) {

  .bg-cftv-thanks .header Button{
    color: #FFFFFF;
    background-color: #525252;
    border-radius: 25px;
    font-size: 10px;
    height: 100%;
    padding: 8px 25px;
  }

  .bg-cftv-thanks .header Button b {
    font-size: 12pt;
  }

}